export const normalizeArticle = article => ({
  ...article,
  title: article.articleTitle,
  subtitle: article.articleSubtitle,
  reader: article.articleReader,
  author: article.authorOfPublication,
  publicationDateTime: article.postDateTime,
  content: article.articleContent,
  organizations: article.organization,
});

export const getCappedText = (text, cap) => {
  return text && text.length > cap ? text.substring(0, cap - 3) + "..." : text;
};

export const getNormalizedReader = json => {
  if (
    !json ||
    !json.content ||
    !json.content[0] ||
    !Array.isArray(json.content[0].content)
  ) {
    return "";
  }
  const normalized = json.content[0].content
    .map(node => {
      if (node.nodeType === "text") {
        return node.value;
      } else if (node.nodeType === "hyperlink") {
        return node.content[0].value;
      }
    })
    .join("");

  return normalized;
};

export const getJsonAsText = json => {
  if (!json || !json.content || !json.content.length) {
    return "";
  }

  const text = json.content
    .map(node => {
      if (node.nodeType === "text") {
        return node.value;
      } else {
        return getJsonAsText(node, false);
      }
    })
    .join("");
  return text;
};
