const moment = require("moment");

export const formatDate = (date, locale) => {
  if (!date) {
    return null;
  }
  moment.locale(locale);
  date = moment(date);

  if (locale !== "de-DE") return date.format("DD MMMM YYYY");
  return date.format("DD. MMMM YYYY");
};
